'use client';
import { FC } from 'react';

import { switchLocale } from '@/actions/settings.actions';
import { useMutation } from '@tanstack/react-query';
import { cn } from '../../lib/utils';

type Props = {
    locale: string;
};

const LangSwitcher: FC<Props> = ({ locale }) => {
    const { mutate: setLanguage } = useMutation({
        mutationFn: async (locale: string) => switchLocale(locale),
        onSuccess: () => {
            window.location.reload();
        }
    });

    return (
        <button
            className="flex gap-2"
            onClick={() => setLanguage(locale === 'cs' ? 'en' : 'cs')}>
            <span
                className={cn('fi', {
                    'fi-cz': locale === 'en',
                    'fi-us': locale === 'cs'
                })}></span>
            <span className="font-bold">{locale === 'cs' ? 'EN' : 'CZ'}</span>
        </button>
    );
};

export default LangSwitcher;
