'use client';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslate } from '@tolgee/react';
import Image from 'next/image';
import React, { PropsWithChildren, useState } from 'react';
import { GiPartyPopper } from 'react-icons/gi';
import AkLogo from '../AkLogo';
import MaxWidthWrapper from '../MaxWidthWrapper';
import CoinsTab from './explore/CoinsTab';
import GirlsTab from './explore/GirlsTab';
import PartyTab from './explore/PartyTab';

enum Tabs {
    PARTY = 'party',
    GIRLS = 'girls',
    COINS = 'coins'
}

const ExploreSection = () => {
    const { t } = useTranslate('landingPage');
    const [activeTab, setActiveTab] = useState(Tabs.GIRLS);
    const renderTab = (tab: Tabs) => {
        switch (tab) {
            case Tabs.PARTY:
                return (
                    <AnimatedTab key={activeTab}>
                        <PartyTab />
                    </AnimatedTab>
                );
            case Tabs.COINS:
                return (
                    <AnimatedTab key={activeTab}>
                        <CoinsTab />
                    </AnimatedTab>
                );
            default:
                return (
                    <AnimatedTab key={activeTab}>
                        <GirlsTab />
                    </AnimatedTab>
                );
        }
    };
    return (
        <div
            className="min-h-56"
            id="explore">
            <MaxWidthWrapper className="flex flex-col items-center justify-center gap-4 py-10">
                <h1
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: t('explore.title') }}></h1>
                <h4
                    className="text-center text-muted-foreground"
                    dangerouslySetInnerHTML={{ __html: t('explore.description') }}></h4>
                <div className="relative mt-5 grid w-full max-w-2xl grid-cols-3 place-items-center items-center gap-4">
                    <span
                        className="relative flex w-full cursor-pointer flex-col items-center justify-center gap-2 pb-3"
                        onClick={() => setActiveTab(Tabs.GIRLS)}>
                        {activeTab === Tabs.GIRLS && <BgActive />}
                        <AkLogo
                            size={35}
                            fill="white"
                        />
                        <span>AK Girls</span>
                    </span>
                    <span
                        className="relative flex w-full cursor-pointer flex-col items-center justify-center gap-2 pb-3"
                        onClick={() => setActiveTab(Tabs.COINS)}>
                        {activeTab === Tabs.COINS && <BgActive />}
                        <Image
                            src="/akcoin.png"
                            width={35}
                            height={35}
                            alt=""
                        />
                        <span>AK coins</span>
                    </span>
                    <span
                        className="relative flex w-full cursor-pointer flex-col items-center justify-center gap-2 pb-3"
                        onClick={() => setActiveTab(Tabs.PARTY)}>
                        {activeTab === Tabs.PARTY && <BgActive />}
                        <GiPartyPopper size={35} />
                        <span>{t('akParty.title')}</span>
                    </span>
                    <div className="absolute bottom-0 z-10 col-span-3 h-px w-full bg-white/10" />
                </div>
                <AnimatePresence mode="wait">{renderTab(activeTab)}</AnimatePresence>
            </MaxWidthWrapper>
        </div>
    );
};

const AnimatedTab: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0, x: -50, height: '100%' }}
            animate={{ opacity: 1, x: 0, height: '100%' }}
            exit={{ opacity: 0, x: 50, height: '100%' }}
            transition={{ duration: 0.3 }}
            className="w-full overflow-hidden">
            {children}
        </motion.div>
    );
};

const BgActive: React.FC = () => {
    return (
        <motion.div
            layoutId="bg"
            className="absolute inset-x-0 bottom-0 z-30 h-px bg-primary"></motion.div>
    );
};

export default ExploreSection;
