import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
    'akButton inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'defaultGradient hover:bg-primary/90 text-white',
                girl: 'girlGradient text-primary-foreground hover:bg-primary/90',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
                gradient: 'defaultGradient'
            },
            gradientAnimation: {
                on: 'gradientAnimation'
            },
            size: {
                default: 'h-12 px-8 py-2',
                sm: 'h-9 px-3',
                lg: 'h-11 px-8',
                icon: 'h-12 w-12',
                smIcon: 'h-9 w-9'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default'
        }
    }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, gradientAnimation, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
        <Comp
            className={cn(
                buttonVariants({
                    variant,
                    size,
                    className,
                    gradientAnimation
                })
            )}
            ref={ref}
            {...props}
        />
    );
});
Button.displayName = 'Button';

export const bbuttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap text-sm rounded-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-primary text-white hover:bg-primary/90',
                girl: 'girlGradient text-white hover:bg-primary/90',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                success: 'bg-green-500 text-white hover:bg-green-600',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
                gradient: 'defaultGradient'
            },
            size: {
                default: 'h-10 px-5',
                sm: 'h-9 px-3',
                xs: 'h-6 px-2 !rounded-[4px]',
                lg: 'h-11 px-5',
                icon: 'h-10 w-10'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default'
        }
    }
);

export type BButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof bbuttonVariants> & { children: React.ReactNode; loading?: boolean; icon?: React.ReactNode };

export const BButton: React.FC<BButtonProps> = ({ children, className, size, variant, icon, loading = false, ...props }) => {
    return (
        <button
            className={cn(
                bbuttonVariants({
                    variant,
                    size,
                    className
                })
            )}
            {...props}>
            {loading ? (
                <span className="flex items-center justify-center">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-primary"></div>
                </span>
            ) : (
                <span className="flex items-center">
                    {icon ? (
                        <span className="flex items-center gap-2">
                            <span>{icon}</span>
                            <span>{children}</span>
                        </span>
                    ) : (
                        children
                    )}
                </span>
            )}
        </button>
    );
};

export { Button, buttonVariants };
